










import { ClassProperties } from "@/includes/types/util.types";

import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { NotifyAction } from "../..";
import PunishMessage from "../components/PunishMessage.vue";

@Component({
  components: {
    PunishMessage
  }
})
export default class NotifyActionView extends Vue {
  @VModel() model!: NotifyAction

  @Prop() defaultModel!: Partial<ClassProperties<NotifyAction>>
}
